<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img
          src="@/assets/images/icons8-more-info-skin-type-7-96.png"
          class="h-20 ml-2"
        />
        <div class="text-xl font-bold">اطلاعات کاربر</div>
      </div>
      <h2 class="text-xl font-bold">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.gid }}</div>
    </template>
    <template #default>
      <!-- search box -->

      <div class="p-3 bg-blueGray-200 border border-blueGray-300 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-contacts-96.png" class="inline-block h-20" />
          اطلاعات کلی
        </div>

        <div class="mt-3">
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">نام</div>
            <div class="font-bold">{{ user.first_name }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">نام خانوادگی</div>
            <div class="font-bold">{{ user.last_name ?? "-" }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">نام کاربری (یوزرنیم)</div>
            <div class="font-bold">{{ user.username ?? "-" }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">ID عددی</div>
            <div class="font-bold">{{ user.uid }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">تعداد دعوت</div>
            <div class="font-bold">{{ user.add?.all ?? "-" }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">تعداد دعوت یکتا</div>
            <div class="font-bold">{{ user.add?.uni ?? "-" }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">تعداد دعوتی که هنوز ممبر هستند</div>
            <div class="font-bold">{{ user.add?.member ?? "-" }}</div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">دعوت شده توسط</div>

            <div class="font-bold" v-if="user.invitedBy == null">داده ای موجود نیست</div>
            <div class="font-bold" v-else-if="user.invitedBy?.uid == user.uid">
              توسط لینک وارد شده
            </div>
            <div class="font-bold text-sky-500" v-else>
              <router-link
                :to="{
                  name: 'ChannelOwnerSettingShowUser',
                  params: {
                    id: $store.state.user.id,
                    channelId: $route.params.channelId,
                    userId: user.invitedBy.id,
                  },
                }"
              >
                {{ user.invitedBy.first_name }}
              </router-link>
            </div>
          </div>
          <div class="flex flex-wrap gap-2 p-2 odd:bg-blueGray-100">
            <div class="w-36">تاریخ پایان اشتراک</div>
            <div class="font-bold">{{ user.userChannel?.vip_exp?.fa ?? "ندارد" }}</div>
          </div>
        </div>
      </div>

      <!-- branch setting -->

      <MainSettingCard @setSettings="submit('vip')">
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-membership-card-96.png"
            class="inline-block h-20"
          />
          مدیریت VIP
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrCheckSlider v-model:checked="user.userChannel.vip">
            کاربر VIP
          </MnrCheckSlider>
          <div>
            <div class="flex gap-1 mt-3">
              <MnrNumber
                v-model="date.vip.day"
                class="w-1/4"
                placeholder="روز"
                hint="روز"
                :counter="false"
                :disabled="!user.userChannel.vip"
              />
              <MnrSelect
                v-model="date.vip.month"
                :options="options.month"
                class="w-2/4"
                placeholder="ماه"
                hint="ماه"
                :disabled="!user.userChannel.vip"
              />
              <MnrNumber
                v-model="date.vip.year"
                class="w-1/4"
                placeholder="سال"
                hint="سال"
                :counter="false"
                :disabled="!user.userChannel.vip"
              />
            </div>
          </div>
        </div>
      </MainSettingCard>

      <!-- other setting -->

      <!-- <MainSettingCard @setSettings="submit('other_setting')">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-settings-96.png" class="inline-block h-20" />
          سایر تنظیمات کاربر در گروه
        </div>

        <MnrCheckSlider v-model:checked="user.noAdd">
          معافیت کاربر از دعوت اجباری
        </MnrCheckSlider>
      </MainSettingCard> -->

      <!-- change admin -->

      <MainSettingCard
        @setSettings="submit('admin')"
        :disabled="user.userChannel.status == 'creator'"
        v-if="
          ['creator', 'administrator'].find((x) => x == user.userChannel.status) ||
          user.userChannel.admin
        "
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-admin-settings-male-96.png"
            class="inline-block h-20"
          />
          ویرایش ادمین
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="user.userChannel.admin"
            :options="options.admin"
            title="سطح ادمین"
            :disabled="user.userChannel.status == 'creator'"
          />
        </div>
        <ul class="mt-5 list-disc list-inside">
          <li>سطح 6: این سطح مخصوص سازنده کانال است و تمام دسترسی ها را دارد</li>
          <li>
            سطح 5: این سطح همه دسترسی ها را دارد اما نمی‌تواند دسترسی سطح 6 را تغییر دهد
          </li>
          <li>سطح 4: این سطح نمیتواند دسترسی هیچ سطح دیگری را تغییر دهد</li>
          <li>
            سطح 3: این سطح هیچ محدودیتی در کانال ندارد و میتواند از دستورات ربات در کانال
            استفاده کند اما نمی‌تواند وارد پنل شود
          </li>
          <li>
            سطح 2: این سطح نمیتواند به ربات دستور دهد و محدودیت ارسال لینک و ادد اجباری در
            صورت فعال بودن در تنظیمات کانال برای این سطح اعمال می‌شود
          </li>
          <li>سطح 1: این سطح تمام محدودیت های یک ممبر معمولی در کانال را دارد</li>
        </ul>
        <div class="text-lg font-bold" v-if="res.result?.is_anonymous">
          <img
            src="@/assets/images/icons8-anonymous-mask-96.png"
            class="inline-block h-20"
          />
          <p class="inline-block">
            این ادمین یک ادمین ناشناس است. یعنی کاربران این ادمین را در کانال نمیتوانند
            ببینند.
          </p>
        </div>
      </MainSettingCard>

      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-slider-96.png" class="inline-block h-20" />
          ویرایش سریع کاربر در کانال
        </div>

        <div class="flex gap-3 mt-3">
          <Button class="inline-flex" color="teal" @click="submit('unbanChatMember')">
            رفع محدودیت
          </Button>
          <Button class="inline-flex" color="red" @click="submit('banChatMember')">
            اخراج کردن
          </Button>
        </div>
        <ul class="mt-5 list-disc list-inside">
          <li>
            <b>رفع محدودیت:</b>
            اگر کاربر در کانال محدود باشد یا اخراج شده باشد و نتواند به کانال بازگردد با
            زدن این دکمه این محدودیت ها رفع می‌شود
          </li>
          <li>
            <b>اخراج کردن:</b>
            با زدن این گزینه کاربر در کانال شما برای همیشه اخراج می‌شود و نمی‌تواند
            بازگردد مگر این که دکمه رفع محدودیت را بزنید
          </li>
        </ul>
      </div>

      <!-- change status -->

      <!-- <MainSettingCard
        @setSettings="submit('status')"
        :disabled="user.userChannel.status == 'creator'"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-bot-96.png" class="inline-block h-20" />
          تغییر وضعیت
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            :options="options.status"
            title="انتخاب وضعیت"
            :disabled="user.userChannel.status == 'creator'"
          />
        </div>
      </MainSettingCard> -->
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import MnrSelect from "@/components/mnr/MnrSelect.vue";

import _ from "lodash";
import MainSettingCard from "@/components/MainSettingCard.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import Button from "@/components/Button.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrSelect,
    MainSettingCard,
    MnrNumber,
    Button,
    MnrCheckSlider,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      channel: {},
      user: {},
      add: {},
      L_: _,
      res: {},
      sending: false,

      date: {},
      options: {
        admin: [
          {
            text: "سطح 6",
            value: 6,
          },
          {
            text: "سطح 5",
            value: 5,
          },
          {
            text: "سطح 4",
            value: 4,
          },
          {
            text: "سطح 3",
            value: 3,
          },
          {
            text: "سطح 2",
            value: 2,
          },
          {
            text: "سطح 1",
            value: 1,
          },
        ],
        exp: [
          {
            text: "برای همیشه",
            value: "ever",
          },
          {
            text: "تا تاریخ مشخص",
            value: "date",
          },
        ],
        status: [
          {
            text: "restricted",
            hint: "محدود",
            value: "restricted",
          },
          {
            text: "kicked",
            hint: "اخراج",
            value: "kicked",
          },
          {
            text: "member",
            hint: "کاربر",
            value: "member",
          },
          {
            text: "administrator",
            hint: "ادمین",
            value: "administrator",
          },
        ],
        month: [
          {
            text: "فروردین",
            hint: 1,
            value: 1,
          },
          {
            text: "اردیبهشت",
            hint: 2,
            value: 2,
          },
          {
            text: "خرداد",
            hint: 3,
            value: 3,
          },
          {
            text: "تیر",
            hint: 4,
            value: 4,
          },
          {
            text: "مرداد",
            hint: 5,
            value: 5,
          },
          {
            text: "شهریور",
            hint: 6,
            value: 6,
          },
          {
            text: "مهر",
            hint: 7,
            value: 7,
          },
          {
            text: "آبان",
            hint: 8,
            value: 8,
          },
          {
            text: "آذر",
            hint: 9,
            value: 9,
          },
          {
            text: "دی",
            hint: 10,
            value: 10,
          },
          {
            text: "بهمن",
            hint: 11,
            value: 11,
          },
          {
            text: "اسفند",
            hint: 12,
            value: 12,
          },
        ],
      },
      setting: {
        showAdd: false,
        showChange: false,
        value: 0,
        options: [
          {
            value: 0,
            text: "پیشفرض",
          },
        ],
      },
      globalDef: {
        help: false,
        pro: false,
        user: {
          value: 0,
          options: [
            {
              value: 0,
              text: "خاموش",
            },
            {
              value: 1,
              text: "محدود کردن کاربر",
            },
            {
              value: 2,
              text: "حذف کاربر",
            },
          ],
        },
        spam: {
          value: 0,
          options: [
            {
              value: 0,
              text: "خاموش",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .get(
          `/api/owner-channels/${$this.$route.params.channelId}/user/${$this.$route.params.userId}`,
          {
            params: {
              id: $this.$store.state.user.id,
              search: $this.searchTools,
              date: $this.date,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.user = response.data.user;
          $this.date = response.data.date;
          $this.res = response.data.res;

          // if ($this.user.userChannel.setting_exp != null) {
          //   $this.metaData.exp.type = "date";
          // }
          // $this.add = response.data.add;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          // $this.sending = false;
        });
    },
    submit(update) {
      let $this = this;
      $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put(
          `/api/owner-channels/${$this.$route.params.channelId}/user/${$this.$route.params.userId}`,
          {
            id: $this.$store.state.user.id,
            update: update,
            user: $this.user,
            date: $this.date,
          }
        )
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
